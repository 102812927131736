var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[_c('div',{staticClass:"modal align-items-center",attrs:{"role":"dialog","aria-labelledby":"modalTitle","aria-describedby":"modalDescription","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-dialog-scrollable"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"border-0 modal-header justify-content-center"},[_c('button',{staticClass:"btn-close",attrs:{"type":"button"},on:{"click":_vm.close}})]),_c('div',{staticClass:"p-4 modal-body no-border"},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"text-center col-12 meetBox"},[_c('h5',{staticClass:"mb-4 bold"},[_vm._v(" "+_vm._s(_vm.$t("nomination.chooseATimeSlotForTheTalentInterview"))+" "+_vm._s(_vm.currentTalent?.talent?.basicInfo?.full_name)+" ")]),_c('div',{staticClass:"book-meeting-mb"},[(_vm.isLoadingCalendly)?_c('vue-calendly',{attrs:{"url":`${_vm.url}&full_name=${_vm.user.first_name}&email=${
                    _vm.user.email
                  }&a2=${
                    _vm.isNomination
                      ? _vm.currentTalent?.talent?.basicInfo?.sharable_link
                      : _vm.sharableLink
                  }${
                    _vm.phone_number
                      ? '&a1=' + encodeURIComponent(_vm.phone_number)
                      : ''
                  }${_vm.guests ? '&guests=' + _vm.guests : ''}`}}):_vm._e()],1)])]),(!_vm.isLoading)?_c('div',{staticClass:"text-center col-12 meetBox d-flex flex-column justify-content-center"},[_c('div',{staticClass:"d-flex"},[_c('skeleton-loader-vue',{attrs:{"type":"rect","width":800,"height":30,"animation":"fade","rounded":""}})],1),_c('br'),_c('br'),_vm._l((10),function(index){return _c('div',{key:index,staticClass:"w-full m-auto d-flex justify-content-center"},[_c('skeleton-loader-vue',{staticClass:"mb-4",attrs:{"type":"rect","height":30,"width":500,"animation":"fade","rounded":""}})],1)})],2):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }