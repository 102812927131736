<template>
  <div>
    <hr v-if="full_time_salary || part_time_salary" />
    <div v-for="(item, i) in handleSalaryDataView" :key="i">
      <div class="mb-2 d-flex justify-content-between skill-14" v-if="item.status">
        <div class="text-start d-flex align-items-center">
          <h4 class="mb-0 d-flex flex-grow-1 salary-text">
            <img
              src="../../../../../assets/images/ic_salary.svg"
              class="me-2 ic_salary"
            />
            <span>
            
               {{$t(`sharebleLink.${[item.salaryType]}`)}}
            </span>
          </h4>
        </div>
        <div class="text-end text-grey">
          <p class="mb-0">
            <span
              v-if="item.salaryValue"
              class="d-flex justify-content-end"
              >{{ item.salaryValue }} $</span
            >
          </p>
          <p class="mb-0">
            <span class="d-flex justify-content-end tw-text-[18px] tw-font-bold " v-if="!item.salaryValue"
              >
                  -
              </span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["full_time_salary", "part_time_salary" ,"full_time_status", "part_time_status"],
  computed: {
    handleSalaryDataView() {
      const salaryDataList = [
        {
          salaryType: "fullTimeSalary",
          salaryKey: "full_time_salary",
          salaryValue: this.full_time_salary,
          status: this.full_time_status,
        },
        {
          salaryType: "partTimeSalary",
          salaryKey: "part_time_salary",
          salaryValue: this.part_time_salary,
          status: this.part_time_status,
        },
      ];
      return salaryDataList;
    },
  },
};
</script>

<style lang="scss" scoped>
.salary-text {
  span {
    font-size: 16px;
  }
}
.ic_salary {
  height: 20px;
  width: 20px;
}
</style>
