
<template>
  <div>
    <div class="shareable-link__certificates" v-if="!isResponse">
      <div>
        <div class="position-relative d-flex justify-content-between">
          <h3 class="headerBorder" v-if="isResponse">
            <span>  
               {{$t(`sharebleLink.certificates`)}}
             </span>
          </h3>

          <div
            class="d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="shareable-link__certificates"
      v-if="
        isResponse && profile.certifications && profile.certifications.length
      "
    >
      <div>
        <div class="position-relative d-flex justify-content-between">
          <h3 class="headerBorder" v-if="isResponse">
            <span>
            {{$t(`sharebleLink.certificates`)}}</span>
          </h3>
          <div class="d-flex align-items-start">
            <img
              @click="showCertificates = !showCertificates"
              class="shareable-link__certificates__open"
              src="@/assets/images/icons/ic_arrow-dropdown_8.svg"
              :class="
                showCertificates
                  ? 'shareable-link__certificates__open-show'
                  : 'shareable-link__certificates__open-hide'
              "
            />
          </div>
          <div
            class="mb-3 d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
        <div
          class="mb-3 d-flex align-items-center justify-content-start"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div
          class="mb-3 d-flex align-items-center justify-content-start"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div
          class=""
          style="margin-top: 20px"
          v-if="
            profile.certifications &&
            profile.certifications.length &&
            showCertificates
          "
        >
          <div
            class="exp-box"
            v-for="certificate in profile.certifications"
            :key="certificate.id"
          >
            <div class="position-relative">
              <div class="d-flex position-relative-box">
                <span
                  class="d-block text-grey"
                  v-if="certificate.start_month && certificate.start_year"
                >
                  <span>
                    ({{ getMonth(certificate.start_month) }}
                    {{ certificate.start_year }} -
                  </span>
                  <span v-if="certificate.not_expire == 1">
                     {{$t('talent.doesntExpire')}} </span
                  >
                  <span v-if="certificate.not_expire == 0"
                    >{{ getMonth(certificate.end_month) }}
                    {{ certificate.end_year }}</span
                  >)
                </span>
                <h3 class="mb-2">
                  {{ certificate.name }} <br />
                  <span class="cert-name mt-2 d-flex"
                    >{{ certificate.organization }}
                  </span>
                </h3>
              </div>

              <p class="mb-2" v-if="certificate.identifier">
                <span class="text-purple">{{ $t('sharebleLink.credentialID') }} :</span>
                {{ certificate.identifier }}
              </p>

              <a
                class="mt-1"
                style="text-align: left; font-size: 15px; color: #422e87"
                :href="getProjectUrl(certificate.url)"
                target="_blank"
                v-if="certificate.url"
                > {{ $t('sharebleLink.viewCertificate') }}</a
              >
            </div>
          </div>
        </div>
        <div
          v-if="
            !(profile.certifications && profile.certifications.length) &&
            isResponse
          "
        >
          No Data
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import months from "@/static/Months";
import { getUrl } from "@/functions/url";

export default {
  props: ["profile", "isResponse"],
  data: () => {
    return {
      months,
      showCertificates: false,
    };
  },
  methods: {
    getProjectUrl(url) {
      return getUrl(url);
    },
    getMonth(id) {
      const filteredMonth = this.months.filter((month) => month.id == id);
      return filteredMonth[0]?.name ? filteredMonth[0]?.name : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.shareable-link__certificates {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;

  &__open {
    height: 10px;
    margin-left: 1rem;
    cursor: pointer;
    margin-top: 10px;
  }

  .position-relative-box {
    flex-direction: column-reverse;
  }

  &__open-show {
    transform: rotate(180deg);
  }

  &__open-hide {
    transform: rotate(0deg);
  }

  .exp-box {
    border: 1px solid #fff;
    box-shadow: none;
    padding: 20px;
    padding-top: 0;

    h3 {
      font-size: 15px;
      color: #292929;
    }
  }
  .exp-box::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 0px;
    border-radius: 0;
    background: rgb(230, 224, 249);
    width: 8px;
    height: 8px;
    outline: 4px solid rgb(255, 255, 255);
  }

  &-credential {
    color: rgb(66, 46, 135) !important;
    font-weight: 600;
    font-size: 16px;
  }

  @media (max-width: 576px) {
    margin-top: 15px;
    padding: 15px;
    margin: 0;
    margin-top: 15px;

    .position-relative-box {
      flex-direction: column-reverse;
    }

    .exp-box {
      .position-relative {
        display: flex;
        flex-direction: column;
      }

      h3 {
        font-size: 17px;
      }
    }

    p {
      font-size: 14px;
    }
  }
}
</style>