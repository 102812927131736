<template>
  <div>
    <div class="shareable-link__educations" v-if="!isResponse">
      <div>
        <div class="d-flex justify-content-between">
          <h3 class="headerBorder" v-if="isResponse">
            <span>                {{$t(`sharebleLink.education`)}} </span>
          </h3>

          <div
            class="d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="shareable-link__educations"
      v-if="
        isResponse &&
        educationHasCommpletedRow &&
        educationHasCommpletedRow.length
      "
    >
      <div>
        <div class="d-flex justify-content-between">
          <h3 class="headerBorder" v-if="isResponse">
            <span>                {{$t(`sharebleLink.education`)}} </span>
          </h3>
          <div class="d-flex align-items-start">
            <img
              @click="showEducation = !showEducation"
              class="shareable-link__educations__open"
              src="@/assets/images/icons/ic_arrow-dropdown_8.svg"
              :class="
                showEducation
                  ? 'shareable-link__educations__open-show'
                  : 'shareable-link__educations__open-hide'
              "
            />
          </div>
          <div
            class="mb-3 d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
        <div
          class="mb-3 d-flex align-items-center justify-content-start"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div
          class="mb-3 d-flex align-items-center justify-content-start"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div
          style="margin-top: 20px"
          class=""
          v-if="
            educationHasCommpletedRow &&
            educationHasCommpletedRow.length &&
            showEducation
          "
        >
          <div
            class="exp-box"
            v-for="education in educationHasCommpletedRow"
            :key="education.id"
          >
            <div class="position-relative">
              <span class="d-block text-grey mb-1">
                ({{ getMonth(education.start_month) }}
                {{ education.start_year }} -
                <span v-if="education.currently == 1">{{$t('sharebleLink.present')}}</span>
                <span v-if="education.currently == 0"
                  >{{ getMonth(education.end_month) }}
                  {{ education.end_year }}</span
                >)
              </span>
              <h3 class="mb-2">
                {{ education.university }}
              </h3>
            </div>
            <p class="mb-0 d-flex flex-column">
              <span class="degree" v-if="education.degree">
                {{ education.degree }}
              </span>
              <span class="studyfield"> {{ education.study_field }}</span>
            </p>
          </div>
        </div>
        <div
          v-if="
            !(educationHasCommpletedRow && educationHasCommpletedRow.length) &&
            isResponse
          "
        >
          No Data
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import months from "@/static/Months";

export default {
  props: ["profile", "isResponse"],
  data: () => {
    return {
      months,
      showEducation: false,
    };
  },
  computed: {
    educationHasCommpletedRow() {
      return (
        this.profile.educations.filter((education) => {
          let educationCheck = { ...education };

          if (educationCheck.parsing_id == null)
            delete educationCheck.parsing_id;

          if (educationCheck.currently) {
            delete educationCheck.end_month;
            delete educationCheck.end_year;
          }
          delete educationCheck.degree;

          return !this.checkProperties(educationCheck);
        }) || []
      );
    },
  },
  methods: {
    getMonth(id) {
      const filteredMonth = this.months.filter((month) => month.id == id);
      return filteredMonth[0].name;
    },
    checkProperties(obj) {
      const isEmpty = !Object.values(obj).every((value) => value !== null);
      return isEmpty;
    },
  },
};
</script>

<style lang="scss" scoped>
.shareable-link__educations {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;

  .position-relative {
    flex-direction: column-reverse;
    display: flex;
  }

  &__open {
    height: 10px;
    margin-left: 1rem;
    cursor: pointer;
    margin-top: 10px;
  }

  &__open-show {
    transform: rotate(180deg);
  }

  &__open-hide {
    transform: rotate(0deg);
  }

  .exp-box {
    border: 1px solid #fff;
    box-shadow: none;
    padding-top: 0;
  }
  .exp-box::before {
    width: 10px;
    height: 10px;
    content: "";
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 0px;
    border-radius: 0;
    background: rgba(230, 224, 249, 1);
    width: 8px;
    height: 8px;
    outline: 4px solid rgb(255 255 255);
  }

  h3 {
    font-size: 15px;
    color: #292929;
  }

  .degree {
    font-size: 15px;
    color: #626262;
    margin-right: 0.5rem;
  }

  .studyfield {
    font-size: 14px;
    color: #898787;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 768px) {
    .exp-box {
      padding: 5px 25px;
    }
  }

  @media (max-width: 576px) {
    margin-top: 15px;
    padding: 15px;
    margin: 0;
    margin-top: 15px;

    .exp-box {
      padding: 20px;
      padding-top: 0;

      .position-relative {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .exp-box::before {
      top: 5px;
    }

    h3 {
      font-size: 17px;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 14px;
    }
  }
}
</style>