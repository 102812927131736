export  const availabilityOptions = [
  { id: 0, name: "locked" },
  { id: 1, name: "immediately" },
  { id: 2, name: "in1Week" },
  { id: 3, name: "in2Weeks" },
  { id: 4, name: "in3Weeks" },
  { id: 5, name: "in4Weeks" },
  { id: 6, name: "in5Weeks" },
  { id: 7, name: "in6Weeks" },
  { id: 8, name: "in7Weeks" },
  { id: 9, name: "in8Weeks" },
  { id: 10, name: "in9Weeks" },
  { id: 11, name: "in10Weeks" },
];

export  const availabilityOptionsTalantProfile = [
  { id: 0, name: "unavailable" },
  { id: 1, name: "immediately" },
  { id: 2, name: "in1Week" },
  { id: 3, name: "in2Weeks" },
  { id: 4, name: "in3Weeks" },
  { id: 5, name: "in4Weeks" },
  { id: 6, name: "in5Weeks" },
  { id: 7, name: "in6Weeks" },
  { id: 8, name: "in7Weeks" },
  { id: 9, name: "in8Weeks" },
  { id: 10, name: "in9Weeks" },
  { id: 11, name: "in10Weeks" },
];
