<template>
  <div class="shareable-link__wrapper" :class="{mini: mini}">
    <div class="container">
  
      <div class="row">
        <div class="col-12">
          <basicInfo :isResponse="isResponse" :profile="profile" :mini="mini" @intervewRequested="intervewRequested" />
        </div>
        <div class="col-12">
          <assessments
            v-if="!mini"
            :isResponse="isResponse"
            :profile="profile"
            :assessments="talantAssessments"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12" :class="{'col-lg-8': !mini }">
          <experience :isResponse="isResponse" :profile="profile" :mini="mini" />
          <projectTypes :isResponse="isResponse" :profile="profile" :mini="mini" />
        </div>
        <div class="col-12" :class="{ 'col-lg-4': !mini }">
          <skills :isResponse="isResponse" :profile="profile" />
          <availability v-if="!mini" :isResponse="isResponse" :profile="profile" />
          <language v-if="!mini" :isResponse="isResponse" :profile="profile" />
          <certificates v-if="!mini" :isResponse="isResponse" :profile="profile" />
          <educations v-if="!mini" :isResponse="isResponse" :profile="profile" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import basicInfo from "@/pages/Client/shareableLinkTalentProfile/basicInfo.vue";
import assessments from "@/pages/Client/shareableLinkTalentProfile/assessments.vue";
import experience from "@/pages/Client/shareableLinkTalentProfile/experience.vue";
import availability from "@/pages/Client/shareableLinkTalentProfile/availability.vue";
import skills from "@/pages/Client/shareableLinkTalentProfile/skills.vue";
import language from "@/pages/Client/shareableLinkTalentProfile/language.vue";
import certificates from "@/pages/Client/shareableLinkTalentProfile/certificates.vue";
import educations from "@/pages/Client/shareableLinkTalentProfile/educations.vue";
import projectTypes from "@/pages/Client/shareableLinkTalentProfile/projectTypes.vue";
import { scrollToTop } from "@/utils/utils.js";

export default {
  components: {
    basicInfo,
    assessments,
    experience,
    availability,
    skills,
    language,
    certificates,
    educations,
    projectTypes,
  },
  props: {
    sharableCode: {
      type: String,
      default: '',
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      profile: {
        educations: [],
        experiences: [],
      },
      talantAssessments: [],
      isResponse: false,
    };
  },
  metaInfo() {
    if (this.mini) return;
    return {
      title: `${this.profile.firsts_name}  ${this.profile.serial_number} | Squadio`,
      meta: [
        {
          name: "description",
          content: this.profile.BasicInfo
            ? `${
                this.vowvelOrNot(this.profile.BasicInfo.role.name) ? "An" : "A"
              } ${this.profile.BasicInfo.role.name} with ${
                this.profile.BasicInfo.experience_years
              } ${
                this.profile.BasicInfo.experience_years == 1 ? "year" : "years"
              } of
          experience`
            : undefined,
        },
        {
          property: "og:description",
          content: this.profile.BasicInfo
            ? `${
                this.vowvelOrNot(this.profile.BasicInfo.role.name) ? "An" : "A"
              } ${this.profile.BasicInfo.role.name} with ${
                this.profile.BasicInfo.experience_years
              } ${
                this.profile.BasicInfo.experience_years == 1 ? "year" : "years"
              } of
          experience`
            : undefined,
        },
        {
          name: "twitter:description",
          content: this.profile.BasicInfo
            ? `${
                this.vowvelOrNot(this.profile.BasicInfo.role.name) ? "An" : "A"
              } ${this.profile.BasicInfo.role.name} with ${
                this.profile.BasicInfo.experience_years
              } ${
                this.profile.BasicInfo.experience_years == 1 ? "year" : "years"
              } of
          experience`
            : undefined,
        },
      ],
    };
  },
  mounted() {
    if (this.mini) {
      this.getProfileData(this.sharableCode);
    } else {
      const normalId = this.$route.params.id;
      this.getProfileData(normalId);
      this.scrollToTop();
    }
  },
  computed: {
    experienceHasAnEmptyRow() {
      return (
        this.profile?.experiences.filter((experience) => {
          let experienceCheck = { ...experience };

          if (experienceCheck.parsing_id == null)
            delete experienceCheck.parsing_id;

          if (experienceCheck.currently_work_in) {
            delete experienceCheck.end_month;
            delete experienceCheck.end_year;
          }
          return this.checkProperties(experienceCheck);
        }) || []
      );
    },
    educationHasAnEmptyRow() {
      return (
        this.profile.educations.filter((education) => {
          let educationCheck = { ...education };

          if (educationCheck.parsing_id == null)
            delete educationCheck.parsing_id;
          if (educationCheck.currently) {
            delete educationCheck.end_month;
            delete educationCheck.end_year;
          }
          delete educationCheck.degree;

          return this.checkProperties(educationCheck);
        }) || []
      );
    },
    experienceHasCommpletedRow() {
      return (
        this.profile.experiences.filter((experience) => {
          let experienceCheck = { ...experience };

          if (experienceCheck.parsing_id == null)
            delete experienceCheck.parsing_id;
          if (experienceCheck.currently_work_in) {
            delete experienceCheck.end_month;
            delete experienceCheck.end_year;
          }
          return !this.checkProperties(experienceCheck);
        }) || []
      );
    },
    educationHasCommpletedRow() {
      return (
        this.profile.educations.filter((education) => {
          let educationCheck = { ...education };

          if (educationCheck.parsing_id == null)
            delete educationCheck.parsing_id;

          if (educationCheck.currently) {
            delete educationCheck.end_month;
            delete educationCheck.end_year;
          }
          delete educationCheck.degree;

          return !this.checkProperties(educationCheck);
        }) || []
      );
    },
  },
  methods: {
    scrollToTop,
    vowvelOrNot(role) {
      if (
        role != "UI/UX Designer" &&
        role != "UI (Visual) Designer" &&
        role != "UX Researcher" &&
        role != "UX Writer" &&
        role != "UI Developer"
      ) {
        return ["a", "e", "i", "o", "u"].includes(role.charAt(0).toLowerCase());
      } else {
        return false;
      }
    },
    intervewRequested() {
      console.log('intervewRequested');
      this.getProfileData(this.$route.params.id);
    },
    async getProfileData(id) {
      try {
        const getProfile = await this.axios
          .get(`/api/client/users/${id}`)
          .then((res) => {
            this.profile = res.data.data;
          });

        const getTalentAssessments = await this.axios
          .get(`/api/client/users/${id}/skills`)
          .then((res) => {
            this.talantAssessments = res.data.data;
          });

        Promise.all([getProfile, getTalentAssessments]).then(() => {
          this.isResponse = true;
        });
      } catch (error) {
        if (
          error?.response?.status == 403 ||
          error?.response?.status == 404 ||
          error?.response?.status == 401
        ) {
          // this.$router.replace("/404");
        }
      }
    },
    checkProperties(obj) {
      const isEmpty = !Object.values(obj).every((value) => value !== null);
      return isEmpty;
    },
  },
};
</script>

<style lang="scss">
.shareable-link__wrapper {

  &.mini {
    padding-bottom: 50px;
  }

  h3,
  h4 {
    font-size: 1.3rem;
  }

  .shareable-link__language-level {
    font-size: 1.3rem;
  }
  .headerBorder span {
    font-size: 21px;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 21px;
      font-weight: 700;
    }
  }
}
</style>