<template>
  <p class="mb-0">
    {{ descriptionComputed }}
    <span @click="show = !show" v-if="description.length > 250"
      >{{ show ? $t('sharebleLink.readLess')  : $t('sharebleLink.readMore')}}
    </span>
  </p>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    descriptionComputed() {
      return this.show
        ? this.description
        : this.description.length > 250 ? this.description.substring(0, 250) + "..." : this.description;
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  cursor: pointer;
  -webkit-text-decoration: line;
  text-decoration: line;
  color: #422e87;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
</style>