<template>
  <div class="shareable-link__availability">
    <div>
      <div class="position-relative">
        <h3 class="mb-4 headerBorder"  v-if="isResponse">
          <span>  {{$t('sharebleLink.availability')}}  </span>
        </h3>
         <div class="mb-3 d-flex align-items-center justify-content-start"  v-if="!isResponse">
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="300"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
      </div>
      <div>
        <Availability
           v-if="isResponse"
          :full_time_status="profile && profile.BasicInfo && profile.BasicInfo.full_time_status"
          :part_time_status="profile && profile.BasicInfo && profile.BasicInfo.part_time_status"
        />
        <div class="mb-3 d-flex align-items-center justify-content-start">
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="400"  
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        
        <Salary
          v-if="isResponse"
          :part_time_salary="profile && profile.BasicInfo && profile.BasicInfo.total_part_time_salary"
          :full_time_salary="profile && profile.BasicInfo && profile.BasicInfo.total_full_time_salary"
          :full_time_status="profile && profile.BasicInfo && profile.BasicInfo.full_time_status"
          :part_time_status="profile && profile.BasicInfo && profile.BasicInfo.part_time_status"
        />
         <div class="mb-3 d-flex align-items-center justify-content-start" v-if="!isResponse">
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
         <div class="mb-3 d-flex align-items-center justify-content-start" v-if="!isResponse">
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Availability from "@/pages/Client/clientViewTalentProfile/components/availability/availability.vue";
import Salary from "@/pages/Client/clientViewTalentProfile/components/salary/salary.vue";
export default {
  props: ["profile", "isResponse"],
  components: { Availability, Salary },
};
</script>

<style lang="scss">
.shareable-link__availability {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;

    hr {
    margin: 1rem 0;
    color: #edebeb;
    border: 0;
    border-top: 1px solid;
    opacity: 1;
  }


  

  .accordion-item {
    border-radius: 30px !important;
    overflow: hidden;
  }

  @media (max-width: 576px) {
    padding: 15px;
    margin: 0;
    margin-top: 15px;
  }
}
</style>