<template>
  <div>
    <div class="shareable-link__language" v-if="!isResponse">
      <div>
        <div class="position-relative">
          <h3 class="headerBorder mb-4" v-if="isResponse">
            <span> {{ $t(`sharebleLink.language`) }} </span>
          </h3>
          <div
            class="mb-3 d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
        <div
          class="mb-3 d-flex align-items-center justify-content-start"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div
          class="mb-3 d-flex align-items-center justify-content-start"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
      </div>
    </div>
    <div
      class="shareable-link__language"
      v-if="isResponse && profile.languages && profile.languages.length"
    >
      <div>
        <div class="position-relative">
          <h3 class="headerBorder mb-4" v-if="isResponse">
            <span> {{ $t(`sharebleLink.language`) }} </span>
          </h3>
          <div
            class="mb-3 d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
        <div
          class="mb-3 d-flex align-items-center justify-content-start"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div
          class="mb-3 d-flex align-items-center justify-content-start"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            v-if="!isResponse"
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div v-if="profile.languages && profile.languages.length">
          <div
            class="row time-skill skill-16 mb-2"
            v-for="language in profile.languages"
            :key="language.id"
          >
            <div class="col-6 text-start">
              <h4 class="mb-0 shareable-link__language-name">
                {{ $t(`languages.${[language.name.toLowerCase()]}`) }}
              </h4>
            </div>
            <div class="col-6 text-end">
              <span class="mb-0 shareable-link__language-level">{{
                getLevel(language.level)
              }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="!(profile.languages && profile.languages.length) && isResponse"
        >
          No Data
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import levels from "@/static/LanguageLevel";

export default {
  props: ["profile", "isResponse"],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    levels() {
      return levels.map((level) => ({
        ...level,
        name: this.$t(`languageLevel.${[level.name]}`),
      }));
    },
  },
  methods: {
    getLevel(id) {
      const filteredLevel = this.levels.filter((level) => level.id == id);
      return filteredLevel[0].name;
    },
  },
};
</script>

<style lang="scss" scoped>
.shareable-link__language {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;

  &-name {
    font-weight: 300;
    font-size: 15px;
    color: #292929;
  }
  &-level {
    font-weight: 500;
    font-size: 15px !important;
    color: #292929;
  }

  @media (max-width: 576px) {
    margin-top: 15px;
    padding: 15px;
    margin: 0;
    margin-top: 15px;

    &-name {
      font-size: 16px;
    }
    &-level {
      font-size: 14px;
    }
  }
}
</style>