const Levels = [{
        "id": 1,
        "name": "Native"
    },
    {
        "id": 2,
        "name": "Advanced"
    },
    {
        "id": 3,
        "name": "Intermediate"
    },
    {
        "id": 4,
        "name": "Beginner"
    }
];

export default Levels;
