<template>
  <div class="shareable-link__basic-info" :class="{ mini: mini }">
    <div class="d-flex shareable-link__basic-info-box">
      <div class="mb-4 d-flex box">
        <div class="shareable-link__basic-info__avater">
          <img
            v-if="isResponse && !profile.image"
            src="@/assets/images/talent-avatar.png"
            class="img-fluid placeholeder"
          />
          <img
            v-if="isResponse && profile.image"
            :src="`${baseUrl}${profile.image.path}`"
            class="talent-real-img img-fluid"
            @error="imageLoadOnError"
          />
          <skeleton-loader-vue
            v-if="!isResponse"
            class="img-fluid-skeleton"
            type="circle"
            :width="160"
            :height="160"
            animation="fade"
          />
          <skeleton-loader-vue
            v-if="!isResponse && !mini"
            class="img-fluid-skeleton-mobile tw-flex md:tw-hidden"
            type="circle"
            :width="90"
            :height="90"
            animation="fade"
          />
          <div
            class="country-flag"
            v-if="profile && profile.country && isResponse"
          >
            <CountryFlag :countryCode="profile.country.code" />
          </div>
        </div>

        <div class="tw-flex lg:tw-hidden" v-if="isResponse">
          <div
            class="shareable-link__basic-info__link shareable-link__basic-info__link-small-screen"
          >
            <span
              v-if="!mini"
              class="share-inner-icon"
              @click="openSharableLinkModal"
            >
            </span>
          </div>
        </div>
      </div>

      <div class="shareable-link__basic-info__ingo">
        <h3
          v-if="
            profile && profile.BasicInfo && profile.BasicInfo.role && isResponse
          "
        >
          {{ $t("sharebleLink.hi") }} 👋, {{ $t("sharebleLink.I’m") }} "{{
            profile.firsts_name
          }}
          {{ mini ? "" : profile.serial_number }}"
          



          <template v-if="profile.BasicInfo.experience_years > 0">
            <br />
              {{ vowvelOrNot(profile.BasicInfo.role.name) ? "An" : "A" }}
              {{ profile.BasicInfo.role.name }}
              <!-- {{ profile.BasicInfo.role.group }} -->

              {{ $t("sharebleLink.with") }}
              {{
                profile.BasicInfo.experience_years > 2
                  ? profile.BasicInfo.experience_years
                  : ""
              }}
              {{ showExperienceYearsText(profile.BasicInfo.experience_years) }}
              {{ $t("sharebleLink.ofExperience") }}

          </template>
           <template v-if="profile.BasicInfo.experience_years == 0 "> 
              {{ $i18n.locale == "en" ? "A" : "" }}
              {{ $t("matchedTalents.fresh") }} {{ profile.BasicInfo.role.name }}
            </template>

            <template v-if="nominatedSkill">
              & 
              <template v-if="nominatedSkill.years > 0">
                 {{ $i18n.locale == "en" ? "A" : "" }}
                 {{ nominatedSkill.name }}
                    {{ $t("sharebleLink.with") }}
                    {{ nominatedSkill.years }}
                    {{
                      nominatedSkill.years == 1
                        ? $t("sharebleLink.year")
                        : $t("sharebleLink.years")
                    }}
                    
                    {{ $t("sharebleLink.ofExperience") }}
              </template>
              <template v-else>
                {{ $i18n.locale == "en" ? "A" : "" }} {{ $t("matchedTalents.fresh") }}  {{ nominatedSkill.name }}                
              </template>

            </template>
           
      
        </h3>
        <div
          class="my-4 d-flex align-items-center justify-content-start w-100"
          v-if="!isResponse"
        >
          <skeleton-loader-vue
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <div class="shareable-link__basic-info__job-contarts">
          <div
            class="skill-tag"
            v-if="
              profile &&
              profile.BasicInfo &&
              profile.BasicInfo.full_time_status &&
              isResponse
            "
          >
            <span class="skill-name">
              {{ $t("sharebleLink.fullTime") }}
            </span>
          </div>
          <div
            class="skill-tag"
            v-if="
              profile &&
              profile.BasicInfo &&
              profile.BasicInfo.part_time_availability == 1 &&
              profile.BasicInfo.part_time_status &&
              isResponse
            "
          >
            <span class="skill-name">
              {{ $t("sharebleLink.partTime") }}
            </span>
          </div>
          <div
            class="mb-3 d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <span class="mr-4">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="100"
                :height="30"
                animation="fade"
                rounded
              />
            </span>
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
      </div>
      <div class="shareable-link__basic-info__link">
        <span
          v-if="!mini"
          class="share-inner-icon"
          @click="openSharableLinkModal"
        >
        </span>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class=" tw-flex tw-flex-col md:tw-flex-row tw-items-start tw-justify-between">
        <p class="shareable-link__basic-info__bio" :class="{'!tw-w-full': !profile.hasOwnProperty('can_be_interviewed') }" v-if="bio && isResponse">
        {{ bio }} {{ !showMoreBio && bio.length > 100 ? "..." : "" }}
        <span
          v-if="profile.BasicInfo.bio.length > 100"
          @click="showMoreBio = !showMoreBio"
          class=""
          >{{
            showMoreBio
              ? $t("sharebleLink.readLess")
              : $t("sharebleLink.readMore")
          }}</span
        >
      </p>
      <template v-if="profile.hasOwnProperty('can_be_interviewed')">
        <button
        v-if="!mini && isResponse && profile.can_be_interviewed === true"
        type="button"
        class="btn btn-primary shareable-link__request-interview-btn"
        @click="requestAnInterview"
      >
       {{ $t("nomination.requestAnInterview") }}
      </button>
       <span
        v-if="!mini && isResponse && profile.can_be_interviewed === false"
        type="button"
        class="btn btn-primary shareable-link__request-interviewed-btn"
      >
        <img src="@/assets/images/icons/ic_success.svg"/>
       
        {{ $t("nomination.interviewScheduled") }}
      </span>
      </template>
      
      </div>


      <button
        v-if="mini && isResponse"
        type="button"
        class="mt-3 btn btn-primary btn-md"
        @click="() => handleRedirection('/client/wizard')"
      >
        {{ `${$t("home.signupToViewMoreLike")} ${profile.firsts_name}` }}
      </button>

      <div
        class="mb-3 d-flex align-items-center justify-content-start"
        v-if="!isResponse"
      >
        <skeleton-loader-vue
          type="rect"
          :width="850"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
      <div
        class="mb-3 d-flex align-items-center justify-content-start"
        v-if="!isResponse"
      >
        <skeleton-loader-vue
          type="rect"
          :width="850"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
    </div>

     <RequestAnInterviewModal
      v-if="isOpen"
      :isOpen="isOpen"
      :isNomination="false"
      @update="isOpen = $event"
      @intervewRequested="$emit('intervewRequested', $event)"
      :currentTalent="currentTalent"
    />

    <Modal
      v-if="isModalOpen"
      @update="isModalOpen = $event"
      :heading="$t('sharebleLink.copyLink')"
      className="sharable-link-modal"
    >
      <template :v-slot="sharable - link"
        ><SharableLink @handleCloseModal="handleCloseModal($event)" />
      </template>
    </Modal>
  </div>
</template>

<script>
import CountryFlag from "@/components/shared/countryFalg/countryFalg.vue";
import Modal from "@/components/shared/modal/modal.vue";
import SharableLink from "@/pages/Client/clientViewTalentProfile/components/SharableLink/SharableLink.vue";
import RequestAnInterviewModal from "@/pages/Client/nominatedProfiles/components/requestAnInterviewModal/requestAnInterviewModal.vue";

export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isResponse: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  components: { CountryFlag, Modal, SharableLink, RequestAnInterviewModal },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
      showMoreBio: false,
      isModalOpen: false,
      display: "big",
      isOpen: false,
      currentTalent: null,
    };
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    nominatedSkill() {
      return this.profile.skills.find((skill) => skill.is_nominated);
    },
    bio() {
      return this.profile &&
        this.profile.BasicInfo &&
        this.profile.BasicInfo.bio
        ? this.showMoreBio
          ? this.profile.BasicInfo.bio
          : this.display == "big"
          ? this.profile.BasicInfo.bio.slice(0, 200)
          : this.profile.BasicInfo.bio.slice(0, 100)
        : "";
    },
  },
  methods: {
    showExperienceYearsText(years) {
      if (years <= 1) return this.$t("matchedTalents.year");
      else if (years == 2) return this.$t("matchedTalents.2years");
      else if (years >= 3 && years <= 10)
        return this.$t("matchedTalents.years");
      else if (years > 10) return this.$t("matchedTalents.year");
    },
    handleRedirection(path) {
      this.$router.push({ path });
    },
    requestAnInterview() {
      this.currentTalent = this.profile;
      this.isOpen = true;
    },
    vowvelOrNot(role) {
      if (
        role != "UI/UX Designer" &&
        role != "UI (Visual) Designer" &&
        role != "UX Researcher" &&
        role != "UX Writer" &&
        role != "UI Developer"
      ) {
        if (role == "Xamarin Developer") {
          return true;
        } else {
          return ["a", "e", "i", "o", "u"].includes(
            role.charAt(0).toLowerCase()
          );
        }
      } else {
        return false;
      }
    },
    imageLoadOnError(e) {
      e.target.src = require("@/assets/images/talent-avatar.png");
      e.target.style.border = "5px solid transparent";
    },
    onResize() {
      if (window.innerWidth > 570) {
        this.display = "big";
      } else {
        this.display = "small";
      }
    },
    openSharableLinkModal() {
      this.isModalOpen = true;
    },
    handleCloseModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.shareable-link__request-interview-btn {
    font-size: 22px;
    color: rgba(0, 0, 0, 1);
    line-height: 25px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8.95px;
        margin-inline-start: auto;

      @media (max-width: 576px) {
        width: 100%;
        margin-top: 1rem;
        font-size: 18px !important;
        margin-inline-start: 0;
      }

}

.shareable-link__request-interviewed-btn {
    font-size: 22px;
    color: #1b1b1b;
    line-height: 25px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8.95px;
    background-color: rgba(59, 212, 174, 0.1) !important;
    border-color: rgba(59, 212, 174, 0.1) !important;
    font-weight: 500;
        margin-inline-start: auto;


    img {
        height: 25px;
        width: 25px;
        margin-inline-end: 5px;
    }

      @media (max-width: 576px) {
        width: 100%;
        margin-top: 1rem;
        font-size: 18px !important;
        margin-inline-start: 0;
      }

}

.shareable-link__basic-info {
  @media (max-width: 576px) {
    .country-flag-wrapper {
      height: 20px;
      width: 20px;
    }
  }
}
.shareable-link__basic-info {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;

  br {
    height: 1px;
    display: block;
    content: "";
    margin-top: 4px;
    line-height: 22px;
  }

  .country-flag {
    position: absolute;
    bottom: 0;
    right: 15%;
    @media (max-width: 576px) {
      bottom: 5%;
      right: 5%;
    }
  }

  .mr-4 {
    margin-right: 1rem;
  }

  @media (max-width: 576px) {
    padding: 15px;
    margin: 0;
    margin-top: 15px;
    .shareable-link__basic-info__link-small-screen {
      display: none;
    }

    .img-fluid-skeleton {
      display: flex;
    }

    .img-fluid-skeleton-mobile {
      display: none;
    }

    br {
      height: 1px;
      margin-top: 0px;
      line-height: 0px;
    }

    .img-fluid-skeleton {
      display: none;
    }

    .img-fluid-skeleton-mobile {
      display: flex;
    }

    &-box .box {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 0 !important;
    }

    &__ingo h2 {
      font-size: 14px;
    }
    &__avater {
      width: 90px !important;
      height: 90px !important;
    }

    &__avater img {
      width: 90px !important;
      height: 90px !important;
    }

    &__bio {
      font-size: 14px !important;
      width: 100% !important;
      margin-top: 0rem !important;
    }

    .shareable-link__basic-info__job-contarts .skill-tag .skill-name {
      font-size: 14px;
    }

    .shareable-link__basic-info__link {
      display: none;
    }
    .shareable-link__basic-info__link-small-screen {
      display: flex;
      flex-direction: column;
      height: 40px;
      width: 40px;
      .share-inner-icon {
        height: 40px;
        width: 40px;
      }
    }

    .shareable-link__basic-info-box {
      flex-wrap: wrap;
    }
    .shareable-link__basic-info__ingo {
      flex-direction: column;
      width: 100%;

      h3 {
        font-size: 21px;
        padding: 12px 0;
        line-height: 30px;
        margin-bottom: 0;
      }
    }
    .shareable-link__basic-info__job-contarts {
      display: flex;
    }
    .shareable-link__basic-info__job-contarts-small-screen {
      display: none;
      flex-direction: column;
    }
  }

  &.mini {
    h3 {
      font-size: 21px;
      padding: 12px 0;
      line-height: 30px;
      margin-bottom: 0;
    }
  }

  .shareable-link__basic-info__link {
    margin-left: auto;
  }

  &__avater {
    img {
      width: 160px;
      margin: 0;
      margin-right: 2rem;
      border-radius: 50%;
      height: 160px;
      position: relative;
      border: 3px solid #e4e4e4;
      object-fit: cover;
    }

    .placeholeder {
      border: none;
    }

    width: 160px;
    margin: 0;
    margin-right: 2rem;
    border-radius: 50%;
    height: 160px;
    position: relative;
  }

  &__ingo {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    flex-direction: column;

    h3 {
      width: 100%;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 1.5rem;
    }
  }

  &__link {
    .share-inner-icon {
      border: 1px solid rgb(242, 242, 242);
      background: url(/img/ic_share.95d8739a.svg) no-repeat;
      border-radius: 9px;
      display: inline-block;
      background-position: center;
      background-size: 20px;
      height: 46px;
      width: 46px;

      &:hover {
        cursor: pointer;
        background: url(/img/ic_share-active.a60cfcc2.svg) no-repeat center
          center;
        background-size: 20px;
      }
    }
  }

  &__job-contarts {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .skill-tag {
      display: flex;
      flex-wrap: wrap;
      background-color: #f8f8f8;
      border-radius: 23px;
      color: #292929;
      padding: 10px 26px;
      margin-right: 10px;
      margin-bottom: 10px;
      .skill-name {
        margin-right: 5px;
        font-weight: 600;
        font-size: 18px;
        color: #292929;
      }
    }
  }

  &__bio {
    margin-top: 1rem;
    color: #9f9d9d;
    font-size: 18px;
    width: 60%;
    margin: 0;
    margin-inline-end: 3rem;

    
    span {
      cursor: pointer;
      text-decoration: line;
      color: #212529;
      text-decoration-line: underline;
    }
  }
}
</style>