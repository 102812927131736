<template>
  <div>
    <div class="shareable-link__experience" v-if="!isResponse">
      <div>
        <div class="">
          <h3 class="headerBorder mb-4" v-if="isResponse">
            <span> {{$t('sharebleLink.experience')}} </span>
          </h3>
          <div
            class="mb-3 d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
        <div class="" v-if="!isResponse">
          <div class="project-box mb-4" v-for="l in 2" :key="l">
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="shareable-link__experience"
      v-if="
        isResponse &&
        experienceHasCommpletedRow &&
        experienceHasCommpletedRow.length
      "
    >
      <div>
        <div class="">
          <h3 class="headerBorder mb-4" v-if="isResponse">
            <span> {{$t('sharebleLink.experience')}} </span>
          </h3>
          <div
            class="mb-3 d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
        <div class="exp-boxes" v-if="!isResponse">
          <div class="exp-box mb-4" v-for="l in 2" :key="l">
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
          </div>
        </div>
        <div
          class="exp-boxes"
          v-if="experienceHasCommpletedRow && experienceHasCommpletedRow.length"
        >
          <div
            class="exp-box mb-4"
            v-for="experience in experienceHasCommpletedRow"
            :key="experience.id"
          >
            <template>
              <div class="position-relative">
                <h3 class="mb-2 experience-title" :class="{ 'tw-font-semibold': mini }">
                  {{ experience.title }}
                  <span class="company-name"> {{ experience.company }} </span>
                </h3>
                <span class="d-block text-grey mb-1">
                  ({{ getMonth(experience.start_month) }}
                  {{ experience.start_year }} -
                  <span v-if="experience.currently_work_in == 1">{{$t('sharebleLink.present')}}</span>
                  <span v-if="experience.currently_work_in == 0"
                    >{{ getMonth(experience.end_month) }}
                    {{ experience.end_year }}</span
                  >
                  )
                </span>
                <hr />
              </div>
              <experienceDescription :description="experience.description" />
            </template>
          </div>
        </div>
        <div
          v-if="
            !(
              experienceHasCommpletedRow && experienceHasCommpletedRow.length
            ) && isResponse
          "
        >
          No Data
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import months from "@/static/Months";
import experienceDescription from "@/pages/Client/shareableLinkTalentProfile/experienceDescription.vue";

export default {
  props: {
    profile: {
      type: Object,
      default: () => { },
    },
    isResponse: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  components: { experienceDescription },
  data: () => {
    return {
      months,
    };
  },
  computed: {
    experienceHasCommpletedRow() {
      return (
        this.profile.experiences.filter((experience) => {
          let experienceCheck = { ...experience };

          if (experienceCheck.parsing_id == null)
            delete experienceCheck.parsing_id;
          if (experienceCheck.currently_work_in) {
            delete experienceCheck.end_month;
            delete experienceCheck.end_year;
          }
          return !this.checkProperties(experienceCheck);
        }) || []
      );
    },
  },
  methods: {
    getMonth(id) {
      const filteredMonth = this.months.filter((month) => month.id == id);
      return filteredMonth[0].name;
    },
    checkProperties(obj) {
      const isEmpty = !Object.values(obj).every((value) => value !== null);
      return isEmpty;
    },
  },
};
</script>

<style lang="scss" scoped>
.shareable-link__experience {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;

  .exp-box::after {
    background: rgba(255, 243, 210, 1);
    content: '';
    position: absolute;
    left: -30px;
    height: 20px;
    width: 20px;
    top: 15px;
    z-index: 2;
    border-radius: 50%;
}

  

  .project-box {
    background-color: #fafafa;
    padding: 25px;
  }

  hr {
    margin: 0.3rem 0;
    color: #edebeb;
    border: 0;
    border-top: 1px solid;
    opacity: 1;
    display: none;
  }

  h3 {
    font-size: 17px;
  }

  p {
    font-size: 14px;
    color: #898787;
  }

  .exp-box {
    padding: 20px 36px;
    background-color: #fafafa;
    .company-name {
      color: #422e87;
      font-size: 16px;
      margin-top: 0.4rem !important;
    }
  }

  @media (max-width: 576px) {
    margin-top: 15px;
    padding: 15px;
    margin: 15px 0;

    hr {
      margin: 0.3rem 0;
      color: #edebeb;
      border: 0;
      border-top: 1px solid;
      opacity: 1;
      display: flex;
    }

    .experience-title {
      display: flex;
      flex-direction: column;
    }

    .company-name {
      padding-left: 0;
    }

    .company-name::after {
      display: none;
    }

    .exp-box {
      padding: 20px;
    }

    .exp-box span {
      font-size: 12px;
    }

    h3 {
      font-size: 17px;
    }

    p {
      font-size: 14px;
    }
  }
}
</style>