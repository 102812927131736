var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"availability-wrapper"},[_c('div',{staticClass:"accordion",attrs:{"id":"accordionAvailability"}},[_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":"headingOne"}},[_c('button',{staticClass:"accordion-button collapsed d-flex justify-content-center",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#collapseAvailabilit","aria-expanded":"false","aria-controls":"collapseAvailabilit"}},[(
              _vm.getAvalilabilityStatus(_vm.full_time_status, _vm.part_time_status) ==
              'locked'
            )?_c('span',{staticClass:"text-center d-block"},[_c('span',{staticClass:"available-text"},[_vm._v(" "+_vm._s(_vm.$t('sharebleLink.lockedByTheTalent'))+" ")])]):_vm._e(),(
              _vm.getAvalilabilityStatus(_vm.full_time_status, _vm.part_time_status) !=
              'locked'
            )?_c('span',[_c('span',{staticClass:"available-text"},[_vm._v(" "+_vm._s(_vm.$t('sharebleLink.available')))]),_c('span',{staticClass:"available-value"},[_vm._v(" "+_vm._s(_vm.getAvalilabilityStatus(_vm.full_time_status, _vm.part_time_status))+" ")])]):_vm._e()])]),_c('div',{staticClass:"accordion-collapse collapse",attrs:{"id":"collapseAvailabilit","aria-labelledby":"headingOne","data-bs-parent":"#accordionAvailability"}},[_c('div',{staticClass:"accordion-body"},[(
              _vm.getAvailabilityTimeName(_vm.full_time_status) !== 'locked' ||
              _vm.getAvailabilityTimeName(_vm.part_time_status) == 'locked'
            )?_c('div',{staticClass:"mb-2 d-flex justify-content-between time-skill"},[_c('div',{staticClass:"text-start"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('sharebleLink.fullTime'))+" ")])]),_c('div',{staticClass:"text-end text-grey"},[_c('p',{staticClass:"mb-0 text-end"},[_vm._v(" "+_vm._s(_vm.getAvailabilityTimeName(_vm.full_time_status))+" ")])])]):_vm._e(),(
              _vm.getAvailabilityTimeName(_vm.part_time_status) !== 'locked' ||
              _vm.getAvailabilityTimeName(_vm.full_time_status) == 'locked'
            )?_c('div',{staticClass:"d-flex justify-content-between time-skill"},[_c('div',{staticClass:"text-start"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('sharebleLink.partTime'))+" ")])]),_c('div',{staticClass:"text-end text-grey"},[_c('p',{staticClass:"mb-0 text-end"},[_vm._v(" "+_vm._s(_vm.getAvailabilityTimeName(_vm.part_time_status))+" ")])])]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }