<template>
  <div>
    <div class="shareable-link__experience" v-if="!isResponse">
                <div >
          <h3 class="headerBorder mb-4" v-if="isResponse">
            <span> 
              {{$t('sharebleLink.projectType')}}
             </span>
          </h3>
          <div
            class="mb-3 d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
        <div class="" v-if="!isResponse">
          <div class="project-box mb-4" v-for="l in 2" :key="l">
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
          </div>
        </div>
    </div>

    <div
      class="shareable-link__experience"
      v-if="
        isResponse &&
        profile.projects &&
        Object.keys(profile.projects) &&
        Object.keys(profile.projects).length
      "
    >
      <div>
        <div >
          <h3 class="headerBorder mb-4" v-if="isResponse">
            <span>   {{$t('sharebleLink.projectType')}}</span>
          </h3>
          <div
            class="mb-3 d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
        <div class="" v-if="!isResponse">
          <div class="project-box mb-4" v-for="l in 2" :key="l">
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
            <div class="mb-3 d-flex align-items-center justify-content-start">
              <skeleton-loader-vue
                v-if="!isResponse"
                type="rect"
                :width="850"
                :height="30"
                animation="fade"
                rounded
              />
            </div>
          </div>
        </div>
        <div
          class=""
          v-if="
            profile.projects &&
            Object.keys(profile.projects) &&
            Object.keys(profile.projects).length
          "
        >
          <div
            class="project-box mb-4"
            v-for="(project, id) in Object.keys(profile.projects)"
            :key="id"
          >
            <div class="position-relative">
              <h3 style="margin-bottom: 24px" :class="{ 'tw-font-semibold': mini }">{{ project }}</h3>
            </div>
            <div
              v-for="(projectDetails, i) in profile.projects[project]"
              :key="i"
            >
              <div class="time-skill skill-16">
                <div class="text-start text-grey">
                  <h5 class="d-flex">{{ projectDetails.name }}</h5>
                </div>
                <div class="text-end">
                  <a
                    
                    :href="getProjectUrl(projectDetails.link)"
                    target="_blank"
                    v-if="projectDetails.link"
                    >{{ $t('sharebleLink.viewProject') }}</a
                  >
                </div>
              </div>
              <hr
                v-if="
                  i !== profile.projects[project].length - 1 &&
                  profile.projects[project].length != 1
                "
              />
            </div>
          </div>
        </div>
        <div
          v-if="
            !(
              profile.projects &&
              Object.keys(profile.projects) &&
              Object.keys(profile.projects).length
            ) && isResponse
          "
        >
          No Data
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUrl } from "@/functions/url";

export default {
  props: {
    profile: {
      type: Object,
      default: () => { },
    },
    isResponse: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getProjectUrl(url) {
      return getUrl(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.shareable-link__experience {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;

  hr {
    margin: 1rem 0;
    color: #edebeb;
    border: 0;
    border-top: 1px solid;
    opacity: 1;
  }

  .project-box {
    background-color: #fafafa;
    padding: 25px;
  }

  h3 {
    font-size: 17px;
  }

  h5 {
    font-size: 16px;
  }

  h5:before {
    content: "";
    display: inline-block;
    min-width: 8px;
    height: 8px;
    background: #e0dbf3;
    margin-right: 25px;
    margin-top: 4px;
  }

  .time-skill a {
    color: #422e87 !important;
    font-weight: 600;
    font-size: 14px;
    padding-top: 10px;
    text-align: left;
  }

  .time-skill {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .shareable-link__experience .time-skill {
    justify-content: space-between;
  }

  @media (max-width: 576px) {
    margin-top: 15px;
    padding: 15px;
    margin: 0;
    margin-top: 15px;

    .time-skill {
      display: block;
      flex-direction: column;
      .text-end {
        margin-left: 35px;
      }
    }

    h3 {
      font-size: 17px;
    }

    h5 {
      font-size: 14px;
    }
  }
}

html[dir="rtl"] {
  .time-skill a {
    text-align: right;
  }
}
</style>