var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isResponse)?_c('div',{staticClass:"shareable-link__experience"},[_c('div',[(_vm.isResponse)?_c('h3',{staticClass:"headerBorder mb-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('sharebleLink.projectType'))+" ")])]):_vm._e(),(!_vm.isResponse)?_c('div',{staticClass:"mb-3 d-flex align-items-center justify-content-start"},[(!_vm.isResponse)?_c('skeleton-loader-vue',{attrs:{"type":"rect","width":300,"height":30,"animation":"fade","rounded":""}}):_vm._e()],1):_vm._e()]),(!_vm.isResponse)?_c('div',{},_vm._l((2),function(l){return _c('div',{key:l,staticClass:"project-box mb-4"},[_c('div',{staticClass:"mb-3 d-flex align-items-center justify-content-start"},[(!_vm.isResponse)?_c('skeleton-loader-vue',{attrs:{"type":"rect","width":850,"height":30,"animation":"fade","rounded":""}}):_vm._e()],1),_c('div',{staticClass:"mb-3 d-flex align-items-center justify-content-start"},[(!_vm.isResponse)?_c('skeleton-loader-vue',{attrs:{"type":"rect","width":850,"height":30,"animation":"fade","rounded":""}}):_vm._e()],1),_c('div',{staticClass:"mb-3 d-flex align-items-center justify-content-start"},[(!_vm.isResponse)?_c('skeleton-loader-vue',{attrs:{"type":"rect","width":850,"height":30,"animation":"fade","rounded":""}}):_vm._e()],1)])}),0):_vm._e()]):_vm._e(),(
      _vm.isResponse &&
      _vm.profile.projects &&
      Object.keys(_vm.profile.projects) &&
      Object.keys(_vm.profile.projects).length
    )?_c('div',{staticClass:"shareable-link__experience"},[_c('div',[_c('div',[(_vm.isResponse)?_c('h3',{staticClass:"headerBorder mb-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('sharebleLink.projectType')))])]):_vm._e(),(!_vm.isResponse)?_c('div',{staticClass:"mb-3 d-flex align-items-center justify-content-start"},[(!_vm.isResponse)?_c('skeleton-loader-vue',{attrs:{"type":"rect","width":300,"height":30,"animation":"fade","rounded":""}}):_vm._e()],1):_vm._e()]),(!_vm.isResponse)?_c('div',{},_vm._l((2),function(l){return _c('div',{key:l,staticClass:"project-box mb-4"},[_c('div',{staticClass:"mb-3 d-flex align-items-center justify-content-start"},[(!_vm.isResponse)?_c('skeleton-loader-vue',{attrs:{"type":"rect","width":850,"height":30,"animation":"fade","rounded":""}}):_vm._e()],1),_c('div',{staticClass:"mb-3 d-flex align-items-center justify-content-start"},[(!_vm.isResponse)?_c('skeleton-loader-vue',{attrs:{"type":"rect","width":850,"height":30,"animation":"fade","rounded":""}}):_vm._e()],1),_c('div',{staticClass:"mb-3 d-flex align-items-center justify-content-start"},[(!_vm.isResponse)?_c('skeleton-loader-vue',{attrs:{"type":"rect","width":850,"height":30,"animation":"fade","rounded":""}}):_vm._e()],1)])}),0):_vm._e(),(
          _vm.profile.projects &&
          Object.keys(_vm.profile.projects) &&
          Object.keys(_vm.profile.projects).length
        )?_c('div',{},_vm._l((Object.keys(_vm.profile.projects)),function(project,id){return _c('div',{key:id,staticClass:"project-box mb-4"},[_c('div',{staticClass:"position-relative"},[_c('h3',{class:{ 'tw-font-semibold': _vm.mini },staticStyle:{"margin-bottom":"24px"}},[_vm._v(_vm._s(project))])]),_vm._l((_vm.profile.projects[project]),function(projectDetails,i){return _c('div',{key:i},[_c('div',{staticClass:"time-skill skill-16"},[_c('div',{staticClass:"text-start text-grey"},[_c('h5',{staticClass:"d-flex"},[_vm._v(_vm._s(projectDetails.name))])]),_c('div',{staticClass:"text-end"},[(projectDetails.link)?_c('a',{attrs:{"href":_vm.getProjectUrl(projectDetails.link),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('sharebleLink.viewProject')))]):_vm._e()])]),(
                i !== _vm.profile.projects[project].length - 1 &&
                _vm.profile.projects[project].length != 1
              )?_c('hr'):_vm._e()])})],2)}),0):_vm._e(),(
          !(
            _vm.profile.projects &&
            Object.keys(_vm.profile.projects) &&
            Object.keys(_vm.profile.projects).length
          ) && _vm.isResponse
        )?_c('div',[_vm._v(" No Data ")]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }