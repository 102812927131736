<template>
  <div>
    <div class="shareable-link__skills"  v-if="!isResponse">
    
        <div class="position-relative">
          <h3 class="mb-4 headerBorder" v-if="isResponse">
            <span> {{$t(`sharebleLink.skills`)}}  </span>
          </h3>
          <div
            class="mb-3 d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
        <div>
          <div
            class="flex-wrap mb-3 d-flex align-items-center"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />

            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />

            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />

            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />

            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />
          </div>
        </div>
    </div>
    <div class="shareable-link__skills"  v-if="isResponse && profile.skills && profile.skills.length">
      <div>
        <div class="position-relative">
          <h3 class="mb-4 headerBorder" v-if="isResponse">
            <span> {{$t(`sharebleLink.skills`)}}  </span>
          </h3>
          <div
            class="mb-3 d-flex align-items-center justify-content-start"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
        <div>
          <div
            class="flex-wrap mb-3 d-flex align-items-center"
            v-if="!isResponse"
          >
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />

            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />

            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />
            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />

            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />

            <skeleton-loader-vue
              v-if="!isResponse"
              type="rect"
              :width="100"
              :height="30"
              animation="fade"
              rounded
              class="mr-3"
            />
          </div>

          <div class="tw-grid tw-grid-cols-2 tw-divide-x tw-mb-3 tw-items-start" v-if="profile.BasicInfo.role.id == 3">
            <div class=" tw-text-center tw-flex tw-flex-col">
                <span class="tw-text-[#422e87] tw-text-[13px] tw-font-semibold tw-rounded-[14px] tw-bg-[#7d67c91a] tw-py-[5px] tw-px-5 tw-mb-2  tw-w-max tw-m-auto tw-flex"> {{ profile.BasicInfo.BE_lang }}</span>
                <span
                  class=" tw-text-[#9f9d9d] tw-font-normal tw-text-[14px] tw-tracking-[0.42px] ltr"
                v-if="profile.BasicInfo.experience_years > 0 || profile.BasicInfo.experience_months > 0"
              >
                {{ profile.BasicInfo.experience_years > 0 ? profile.BasicInfo.experience_years + " Y" : "" }}
                <span
                  style="padding: 0"
                  v-if="profile.BasicInfo.experience_years > 0 && profile.BasicInfo.experience_months > 0"
                >
                  &
                </span>
                {{ profile.BasicInfo.experience_months > 0 ? profile.BasicInfo.experience_months + " M" : "" }}

                of experience
              </span>
            </div>
            <div class=" tw-text-center tw-flex tw-flex-col">
                <span class="tw-text-[#422e87] tw-text-[13px] tw-font-semibold tw-rounded-[14px] tw-bg-[#7d67c91a] tw-py-[5px] tw-px-5 tw-w-max tw-m-auto tw-flex tw-mb-2 "> {{ profile.BasicInfo.FE_lang }}</span>
                <span
                  class=" tw-text-[#9f9d9d] tw-font-normal tw-text-[14px] tw-tracking-[0.42px] ltr"
                v-if="profile.BasicInfo.second_experience_years > 0 || profile.BasicInfo.second_experience_months > 0"
              >
                {{ profile.BasicInfo.second_experience_years > 0 ? profile.BasicInfo.second_experience_years + " Y" : "" }}
                <span
                  style="padding: 0"
                  v-if="profile.BasicInfo.second_experience_years > 0 && profile.BasicInfo.second_experience_months > 0"
                >
                  &
                </span>
                {{ profile.BasicInfo.second_experience_months > 0 ? profile.BasicInfo.second_experience_months + " M" : "" }}

                of experience
              </span>
            </div>
          </div>

          
          <hr class="tw-h-px tw-mt-0 tw-mb-3 tw-bg-[#e5e7eb] tw-border-0" v-if="profile.BasicInfo.role.id == 3 && skillsCompleted?.length ">



          <div
            class="skill-tags-wrapper"
            v-if="profile.skills && profile.skills.length"
          >
            <div
              v-for="skill in skillsCompleted"
              :key="skill.id"
              class="skill-tag"
            >
              <span class="skill-name">{{ skill.name }}</span>

              <span
                class="skill-experience"
                v-if="skill.years > 0 || skill.months > 0"
              >
                {{ skill.years > 0 ? skill.years + " Y" : "" }}
                <span
                  style="padding: 0"
                  v-if="skill.years > 0 && skill.months > 0"
                >
                  &
                </span>
                {{ skill.months > 0 ? skill.months + " M" : "" }}

                of experience
              </span>
            </div>
            <span
              class="show-skill"
              @click="show = !show"
              v-if="profile.skills.length > 6"
              >{{ show ? $t('sharebleLink.readLess'): `+ ${profile.skills.length - 6} ${$t('sharebleLink.more')}` }}
            </span>
          </div>
          <div v-if="!(profile.skills && profile.skills.length) && isResponse">
            No Data
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["profile", "isResponse"],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    skillsCompleted() {
      if (this.profile.BasicInfo.role.id !== 3) {
        return this.show
        ? this.profile.skills
        : this.profile.skills.length > 6
        ? this.profile.skills.slice(0, 6)
        : this.profile.skills;  
      }
      else {
        let skills = this.profile.skills.filter(skill => skill.name !== this.profile.BasicInfo.BE_lang && skill.name !== this.profile.BasicInfo.FE_lang)
          return this.show ? skills
        : skills.length > 6
        ? skills.slice(0, 6)
        : skills;    
       
        
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
.ltr {
  direction: ltr;
}

.shareable-link__skills {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;

  .show-skill {
    cursor: pointer;
    -webkit-text-decoration: line;
    text-decoration: line;
    color: #422e87;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }

  .mr-3 {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .skill-tags-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-items: baseline;
  }
  .skill-tag {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid rgb(238, 238, 238);
    border-radius: 18px;
    color: rgb(100, 100, 100);
    padding: 6px 14px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fafafa;
    align-items: center;
    direction: ltr;

  }
  .skill-name {
    font-size: 15px;
    margin-right: 5px;
  }

  .skill-experience {
    font-size: 12px;
    background-color: #f2f2f2;
    padding: 4px 8px;
    border-radius: 5px;
    color: #494d55;
    font-weight: 500;
  }

  @media (max-width: 576px) {
    padding: 15px;
    margin: 0;
    margin-top: 15px;
    .skill-name {
      font-size: 12px;
    }
  }
}
</style>